<template>
  <!-- 已支付 -->
  <div class="no-payment-component">
    <div class="flex-bet">
      <!-- <input type="checkbox" :id="data.orderNo" class="select-item" /> -->
      <div style="flex: 1">
        <label class="no-payment-top" :for="data.orderNo">
          <div
            v-for="item in data.orderInfoVoList"
            :key="item.id"
            style="width: 100%"
          >
            <div class="no-payment-left">
              <div class="no-payment-select flex-cen">
                <div
                  :class="[item.styleType == 0 ? '' : 'border-iframe-outer']"
                >
                  <div
                    :class="[item.styleType == 0 ? '' : 'border-iframe-inner']"
                  >
                    <img src="" v-lazy="item.image" alt="" />
                  </div>
                </div>
              </div>
              <div class="payment-info">
                <div class="payment-title">
                  {{ $trans(`goods-${item.spuId}.title`, item.spuTitle)
                  }}<span class="payment-model"
                    >({{ $trans(status, status) }})</span
                  >
                </div>
                <div class="payment-author">
                  「{{
                    $trans(`artist-${item.artistId}.name`, item.artistName)
                  }}」
                </div>
                <div class="payment-model" style="margin-top: 5px">
                  {{ item.specTitle }}
                </div>
              </div>
            </div>
            <div class="no-payment-right">
              <div class="payment-number">×{{ item.count }}</div>
              <div class="object__price">
                <div style="font-style: normal">￥</div>
                <div class="object__int">{{ item.price | product_int }}</div>
                <div class="object__float">
                  {{ item.price | product_float }}
                </div>
              </div>
            </div>
          </div>
        </label>
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>{{ $trans('小计', '小计') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">
                {{ sumMoney | product_int }}
              </div>
              <div class="object__float">
                {{ sumMoney | product_float }}
              </div>
            </div>
          </div>
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('订单编号', '订单编号') }}</span>
            <div class="no-payment-serial flex-cen">
              <span>{{ data.batchNo }}</span>
              <i
                class="iconfont icon-fuzhi"
                style="cursor: pointer"
                @click="copyGroupId(data.batchNo)"
              ></i>
            </div>
          </div>
          <div class="no-payment-oper flex-cen">
            <div
              class="btn-wrap flex-cen-cen"
              @click="toRefund('/order/index/unpaid/detail')"
            >
              <!-- @click="toRefund('/order/index/accomplish/detail')" -->
              {{ $trans('查看详情', '查看详情') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    data: {
      typeof: Array,
    },
    refund: {
      typeof: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    toRefund(link) {
      this.$router.history.push({
        path: link,
        query: {
          batchNo: this.data.batchNo,
          status: this.data.status,
        },
      })
    },
  },
  computed: {
    status() {
      if (this.data.status == 1) {
        return '待支付'
      } else if (this.data.status == 2) {
        return '待发货'
      } else if (this.data.status == 3) {
        return '已发货'
      } else if (this.data.status == 4) {
        return '退款中'
      } else if (this.data.status == 5) {
        return '交易成功'
      } else if (this.data.status == 7) {
        return '已退款'
      } else {
        return '已关闭'
      }
    },
    sumMoney() {
      var res = this.data.orderInfoVoList.reduce(
        (a, b) => b.price * parseInt(b.count) + a,
        0
      )
      return res
    },
  },
}
</script>
<style scoped>
.no-payment-component {
  padding: 21px 21px 15px 21px;
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  margin-bottom: 14px;
  /* box-shadow: 2px 2px 10px #dfdfdf; */
}
.no-payment-component:last-of-type {
  margin-bottom: 0;
}
.no-payment-top {
  padding-bottom: 30px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}
.no-payment-left {
  display: flex;
  align-items: flex-start;
}
.payment-info {
  margin-left: 30px;
}
.payment-title {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 10px;
}
.payment-model {
  font-size: 12px;
  color: #595959;
}
.payment-author {
  font-size: 12px;
  color: #595959;
  transform: translateX(-6px);
  margin-bottom: 10px;
  /* transform: scale(0.8); */
  /* transform-origin: -30% -20%; */
}
.no-payment-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.payment-number {
  width: 40px;
  height: 40px;
  background: #dfdfdf;
  border-radius: 2px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  transform: scale(0.5);
}
.no-payment-subtotal {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 24px;
}
.no-payment-order {
  font-family: 'myCoolFontRegular';
  color: #666666;
  margin-bottom: 50px;
  font-size: 16px;
}
.no-payment-order > span {
  transform: scale(0.8);
  transform-origin: left;
}
.no-payment-serial {
  transform-origin: right;
  transform: scale(0.8);
}
.no-payment-serial i {
  margin-left: 10px;
}
.no-payment-oper {
  justify-content: flex-end;
  transform-origin: right;
  transform: scale(0.6);
}
.no-payment-oper .btn-wrap {
  padding: 0 20px;
  /* width: 120px; */
  height: 60px;
  border-radius: 7px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #999999;
  font-size: 16px;
  flex-shrink: 0;
}
.no-payment-oper .btn-wrap.btn-fill {
  border: none;
  color: #ffffff;
}
.no-payment-oper .btn-wrap:last-child {
  margin-right: 0;
}
.no-payment-select {
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
}
.no-payment-select input {
  margin-right: 10px;
}
.border-iframe-outer {
  display: inline-block;
  border: 1px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.border-iframe-inner {
  border: 1px solid #ffffff;
}
img {
  max-width: 40px;
  max-height: 40px;
  width: auto;
  height: auto;
  display: block;
}
.select-item {
  width: 16px;
  height: 16px;
  margin-top: 30px;
  margin-right: 20px;
}
</style>
