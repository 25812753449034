<template>
  <div style="position: relative">
    <div class="common-gaine">
      <div class="flex-bet-cen order-header-container">
        <div class="flex-cen">
          <i class="iconfont icon-wodedingdan"></i>
          <div class="common-plate-title">
            {{ $trans('我的订单', '我的订单') }}
          </div>
        </div>
        <div class="flex-cen">
          <!-- <label class="flex-cen all-select">
            <input type="checkbox" class="all-select-input" />
            <span class="font-medium">全选</span>
          </label> -->
          <i
            class="iconfont icon-fanhui1"
            :title="$trans('返回上一页', '返回上一页')"
            @click="$router.history.go(-1)"
          ></i>
        </div>
      </div>
      <div class="order-main-container flex-bet">
        <div class="order-tab-list flex-column-start">
          <div
            class="order-tab-item font-medium"
            v-for="(item, index) in tabList"
            :key="index"
            :data-index="index"
            :data-all="item.all"
            :data-status="item.status"
            :class="tabListActive == index ? 'active' : ''"
            @click="tabListHandle"
          >
            {{ $trans(item.text, item.text) }}
          </div>
        </div>
        <div class="order-container">
          <div
            v-for="(item, index) in data"
            :key="index"
            style="margin-bottom: 20px"
          >
            <NotPay
              @on="getCatagoryOrder"
              :data="item"
              v-if="item.status == '1'"
            />
            <AlreadyPay :data="item" v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import NotPay from '@/components/order/NotPay.vue'
import AlreadyPay from '@/components/order/AlreadyPay.vue'
import { getCatagoryOrder } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      tabList: [
        // 左侧列表
        {
          text: '全部',
          all: true,
        },
        {
          text: '待支付',
          status: 1,
        },
        {
          text: '待发货',
          status: 2,
        },
        {
          text: '已发货',
          status: 3,
        },
        {
          text: '已完成',
          status: 5,
        },
      ],
      tabListActive: 0, // tab选择项
      data: [], // 数据
    }
  },
  mounted() {
    this.getAllOrder()
  },
  methods: {
    // 切换选项
    tabListHandle(e) {
      this.tabListActive = e.target.dataset.index
      if (e.target.dataset.all) {
        this.getAllOrder()
      } else {
        this.getCatagoryOrder(e.target.dataset.status)
      }
    },
    // 获取全部数据
    getAllOrder() {
      getCatagoryOrder({ userId: this.$store.state.userId }).then((res) => {
        this.data = JSON.parse(JSON.stringify(res.data))

        res.data.forEach((item) => {
          item.orderInfoVoList.forEach((it) => {
            if (it.transferList) {
              loadLocaleMessages(it.transferList, `goods-${it.spuId}`)
            }
            if (it.artistTransferList) {
              loadLocaleMessages(it.artistTransferList, `artist-${it.artistId}`)
            }
          })
        })
      })
    },
    // 获取分类数据
    getCatagoryOrder(status) {
      getCatagoryOrder({
        userId: this.$store.state.userId,
        status: status,
      }).then((res) => {
        this.data = res.data ? JSON.parse(JSON.stringify(res.data)) : []
        res.data.forEach((item) => {
          if (item.transferList) {
            loadLocaleMessages(item.transferList, `goods-${item.spuId}`)
          }
          if (item.artistTransferList) {
            loadLocaleMessages(
              item.artistTransferList,
              `artist-${item.artistId}`
            )
          }
        })
      })
    },
  },
  components: {
    NotPay,
    AlreadyPay,
  },
}
</script>
<style scoped>
.order-header-container {
  margin-bottom: 80px;
}
.all-select {
  font-size: 18px;
  color: #999999;
  cursor: pointer;
}
.all-select input {
  margin-right: 10px;
}
.order-tab-item {
  margin-bottom: 50px;
  color: #666666;
  cursor: pointer;
  font-size: 16px;
}
.order-tab-item.active {
  color: #111111;
  font-weight: bold;
}
.order-container {
  width: 660px;
}
</style>
<style>
input.all-select-input {
  width: 18px;
  height: 18px;
}
</style>
