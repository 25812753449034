<template>
  <!-- 待支付 -->
  <div class="no-payment-component">
    <div class="flex-bet">
      <!-- <input type="checkbox" :id="data.orderNo" class="select-item" /> -->
      <div style="flex: 1">
        <label class="no-payment-top" :for="data.orderNo">
          <div
            v-for="item in data.orderInfoVoList"
            :key="item.id"
            style="width: 100%"
          >
            <div class="no-payment-left">
              <div class="no-payment-select flex-cen">
                <div
                  :class="[item.styleType == 0 ? '' : 'border-iframe-outer']"
                >
                  <div
                    :class="[item.styleType == 0 ? '' : 'border-iframe-inner']"
                  >
                    <img src="" v-lazy="item.image" alt="" />
                  </div>
                </div>
              </div>
              <div class="payment-info">
                <div class="payment-title">
                  {{ $trans(`goods-${item.spuId}.title`, item.spuTitle)
                  }}<span class="payment-model"
                    >({{ $trans(status, status) }})</span
                  >
                </div>
                <div class="payment-author">
                  「{{
                    $trans(`artist-${item.artistId}.name`, item.artistName)
                  }}」
                </div>
                <div class="payment-model" style="margin-top: 5px">
                  {{ item.specTitle }}
                </div>
              </div>
            </div>
            <div class="no-payment-right">
              <div class="payment-number">×{{ item.count }}</div>
              <div class="object__price">
                <div style="font-style: normal">￥</div>
                <div class="object__int">{{ item.price | product_int }}</div>
                <div class="object__float">
                  {{ item.price | product_float }}
                </div>
              </div>
            </div>
          </div>
        </label>
        <div class="no-payment-bottom">
          <div class="no-payment-subtotal flex-bet-cen">
            <span>{{ $trans('小计', '小计') }}</span>
            <div class="object__price" style="color: #2a9dcc">
              <div style="font-style: normal">￥</div>
              <div class="object__int">
                {{ sumMoney | product_int }}
              </div>
              <div class="object__float">
                {{ sumMoney | product_float }}
              </div>
            </div>
          </div>
          <div class="no-payment-order flex-bet-cen">
            <span>{{ $trans('订单编号', '订单编号') }}</span>
            <div class="no-payment-serial flex-cen">
              <span>{{ data.batchNo }}</span>
              <i
                class="iconfont icon-fuzhi"
                style="cursor: pointer"
                @click="copyGroupId(data.batchNo)"
              ></i>
            </div>
          </div>
          <div class="no-payment-oper flex-cen">
            <div
              :class="[
                'btn-wrap flex-cen-cen ',
                $i18n.locale == 'EN' || $i18n.locale == 'DE'
                  ? 'width-auto'
                  : '',
              ]"
              @click="toRefund('/order/index/unpaid/detail')"
            >
              {{ $trans('查看详情', '查看详情') }}
            </div>
            <div
              :class="[
                'btn-wrap flex-cen-cen',
                $i18n.locale == 'EN' || $i18n.locale == 'DE'
                  ? 'width-auto'
                  : '',
              ]"
              @click="getOrderClose"
            >
              {{ $trans('取消订单', '取消订单') }}
            </div>
            <div
              :class="[
                'btn-wrap flex-cen-cen btn-fill',
                $i18n.locale == 'EN' || $i18n.locale == 'DE'
                  ? 'width-auto'
                  : '',
              ]"
              @click="open"
            >
              {{ $trans('立即支付', '立即支付') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="true"
      class="wx-payment-dialog flex-cen-cen"
      :visible.sync="dialogFormVisible"
      :before-close="handleClose"
    >
      <div class="flex-cen">
        <div class="flex-column-cen">
          <div id="qrcode1" ref="qrcode1"></div>
          <div class="wram-tip flex-bet-cen">
            <i class="iconfont icon-saoyisao"></i>
            <span>{{
              $trans(
                '请用微信二维码扫描已完成支付',
                '请用微信二维码扫描已完成支付'
              )
            }}</span>
          </div>
        </div>
        <img class="wx-payment-img" src="@/assets/img/payment.png" alt="" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getOrderPay, paymentStateApi, getOrderClose } from '@/util/js/api.js'
import QRCode from 'qrcodejs2'
export default {
  name: '',
  props: {
    data: {
      typeof: Array,
    },
  },
  data() {
    return {
      payment: '', // 支付方式
      QRCode: '', // 微信二维码地址
      dialogFormVisible: false, // 二维码弹出层
      batchNo: this.data.batchNo, // 订单信息
    }
  },
  created() {},
  methods: {
    toRefund(link) {
      this.$router.history.push({
        path: link,
        query: {
          batchNo: this.data.batchNo,
          status: this.data.status,
        },
      })
    },
    // 选择支付方式
    open() {
      const obj = this.getUserInfo.call(this, 'user')
      if (obj && obj['status'] != 3) {
        this.$message.warning(this.$trans('请先完成实名', '请先完成实名'))
        this.$router.history.push('/setting/authentication')
        return
      }
      const h = this.$createElement
      this.$msgbox({
        title: this.$trans('消息', '消息'),
        message: h('div', null, [
          h('div', { style: { 'margin-bottom': '20px' } }, [
            h(
              'input',
              {
                attrs: {
                  type: 'radio',
                  name: 'payment',
                  value: '1',
                  id: 'zfb',
                },
                on: {
                  change: () => {
                    this.payment = '2'
                  },
                },
              },
              ''
            ),
            h(
              'label',
              { attrs: { for: 'zfb' }, style: { 'margin-left': '20px' } },
              this.$trans('支付宝', '支付宝')
            ),
          ]),
          h('div', {}, [
            h(
              'input',
              {
                attrs: { type: 'radio', name: 'payment', value: '0', id: 'wx' },
                on: {
                  change: () => {
                    this.payment = '1'
                  },
                },
              },
              ''
            ),
            h(
              'label',
              { attrs: { for: 'wx' }, style: { 'margin-left': '20px' } },
              this.$trans('微信', '微信')
            ),
          ]),
        ]),
        showCancelButton: true,
        confirmButtonText: this.$trans('确定', '确定'),
        cancelButtonText: this.$trans('取消', '取消'),
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.getOrderPay(done)
          } else {
            done()
          }
        },
      })
    },
    // 支付
    getOrderPay(callback) {
      callback()
      let param = {
        batchNo: this.batchNo,
        payType: this.payment,
      }
      getOrderPay(param)
        .then(
          (res) => {
            if (res.code && (res.code == 500 || res.code == 10000)) {
              this.$message.error(res.msg ? res.msg : '服务器错误')
            } else if (res.code == 20002 || res.msg == '订单支付失败') {
              this.$message.error(
                this.$trans('订单已超时自动取消', '订单已超时自动取消')
              )
              this.dialogFormVisible = false
            } else {
              if (param.payType == 1) {
                this.QRCode = res.url
                this.dialogFormVisible = true
                // this.batchNo = res.batchNo;
                this.$nextTick(() => {
                  document.querySelector('#qrcode1').innerHTML = ''
                  this.decidePaymentState(
                    res.batchNo ? res.batchNo : this.batchNo
                  )
                  return new QRCode(this.$refs.qrcode1, {
                    width: 200, // 宽
                    height: 200, // 高
                    text: this.QRCode, // 生成二维码的内容
                    render: 'canvas', // 设置渲染方式（canvas,table 默认canvas）
                    correctLevel: QRCode.CorrectLevel.L, // 二维码纠错级别（L,M,Q,H）
                  })
                })
              } else {
                document.querySelector('body').innerHTML = res.url
                document.forms[0].submit()
              }
            }
          },
          (err) => {
            this.$message.warning(err.message)
          }
        )
        .catch((err) => {
          this.$message.warning(err.message)
        })
    },
    // 判断微信支付是否完成
    decidePaymentState(batchNo) {
      setTimeout(() => {
        if (this.dialogFormVisible) {
          paymentStateApi({ batchNo }).then((res) => {
            if (res.code == '200') {
              this.dialogFormVisible = false
              this.$router.history.push('/')
            } else {
              return
            }
          })
          this.decidePaymentState(batchNo)
        }
      }, 3000)
    },
    // 微信支付弹窗关闭之前
    handleClose() {
      if (this.dialogFormVisible) {
        this.$message.error(this.$trans('支付失败', '支付失败'))
        this.dialogFormVisible = false
      } else {
        this.$message.success(this.$trans('支付成功', '支付成功'))
        this.$router.history.push('/')
      }
    },
    // 取消订单
    getOrderClose() {
      let params = {
        batchNo: this.data.batchNo,
        userId: JSON.parse(localStorage.getItem('user')).id,
      }
      getOrderClose(params).then((res) => {
        if (res.code != '200') {
          this.$message.error(res.msg)
        } else {
          this.$message.success(this.$trans('取消订单成功', '取消订单成功'))
          this.$emit('on', 1)
        }
      })
    },
  },
  computed: {
    status() {
      if (this.data.status == 1) {
        return '待支付'
      } else if (this.data.status == 2) {
        return '待发货'
      } else if (this.data.status == 3) {
        return '已发货'
      } else if (this.data.status == 4) {
        return '退款中'
      } else if (this.data.status == 5) {
        return '交易成功'
      } else if (this.data.status == 7) {
        return '已退款'
      } else {
        return '已关闭'
      }
    },
    sumMoney() {
      var res = this.data.orderInfoVoList.reduce(
        (a, b) => b.price * parseInt(b.count) + a,
        0
      )
      return res
    },
  },
}
</script>
<style scoped>
.no-payment-component {
  padding: 21px 21px 15px 21px;
  background: #fdfdfd;
  border: 1px solid #dfdfdf;
  margin-bottom: 14px;
  /* box-shadow: 2px 2px 10px #dfdfdf; */
}
.no-payment-component:last-of-type {
  margin-bottom: 0;
}
.no-payment-top {
  padding-bottom: 30px;
  border-bottom: 1px solid #dfdfdf;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-direction: column;
}
.no-payment-left {
  display: flex;
  align-items: flex-start;
}
.payment-info {
  margin-left: 30px;
}
.payment-title {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 10px;
}
.payment-model {
  font-size: 12px;
  color: #595959;
}
.payment-author {
  font-size: 16px;
  color: #595959;
  transform: translateX(-6px);
  margin-bottom: 10px;
}
.no-payment-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.payment-number {
  width: 40px;
  height: 40px;
  background: #dfdfdf;
  border-radius: 2px;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  transform: scale(0.5);
}
.no-payment-subtotal {
  font-size: 16px;
  font-family: 'myCoolFontMedium';
  margin-bottom: 24px;
}
.no-payment-order {
  font-family: 'myCoolFontRegular';
  color: #666666;
  margin-bottom: 50px;
  font-size: 16px;
}
.no-payment-order > span {
  transform: scale(0.8);
  transform-origin: left;
}
.no-payment-serial {
  transform-origin: right;
  transform: scale(0.8);
}
.no-payment-serial i {
  margin-left: 10px;
}
.no-payment-oper {
  justify-content: flex-end;
  transform-origin: right;
  transform: scale(0.6);
}
.no-payment-oper .btn-wrap {
  width: 120px;
  height: 60px;
  border-radius: 7px;
  margin-right: 20px;
  cursor: pointer;
  border: 1px solid #dfdfdf;
  color: #999999;
  font-size: 16px;
  flex-shrink: 0;
}
.no-payment-oper .btn-wrap.btn-fill {
  border: none;
  color: #ffffff;
}
.no-payment-oper .btn-wrap:last-child {
  margin-right: 0;
}
.no-payment-select {
  width: 60px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f4f4f4;
  border-radius: 4px;
}
.no-payment-select input {
  margin-right: 10px;
}
.border-iframe-outer {
  display: inline-block;
  border: 1px solid #111111;
  transition: all 0.5s;
  box-shadow: 3px 3px 3px #999999;
}
.border-iframe-inner {
  border: 1px solid #ffffff;
}
img {
  max-width: 40px;
  max-height: 40px;
  width: auto;
  height: auto;
  display: block;
}
.select-item {
  width: 16px;
  height: 16px;
  margin-top: 30px;
  margin-right: 20px;
}
</style>
<style>
.wx-payment-dialog .el-dialog__body .wx-payment-img {
  width: 300px;
  height: auto;
  margin-left: 60px;
  max-width: unset;
  max-height: unset;
}
.wx-payment-dialog .el-dialog {
  width: unset;
  display: inline-block;
  padding: 20px;
}
.wx-payment-dialog .wram-tip {
  width: 240px;
  border: 2px solid #111111;
  border-radius: 4px;
  padding: 15px;
  margin-top: 40px;
  line-height: 30px;
  font-size: 16px;
}
.wx-payment-dialog .wram-tip .icon-saoyisao {
  font-size: 50px;
  margin-right: 15px;
}
</style>
